.bg-green-dark {
    background-color: #023430;
}
.bg-black-light {
    background-color: #252323;
}

.bg-white-vanilla {
    /* background-color: #47553e; */
    /* background-color: #f5f5f5; */
    /* background-color: #dcdcdc; */
    background-color: #525252;
}

/* .text-gray-light {
    color: #dcdcdc;
} */
